import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
// import ContactUsMap from "./../components/ContactUsMap";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";

const HoursUL = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  & li {
    font-size: 18px;
    display: flex;
    letter-spacing: 2%;
    margin-bottom: 7.2px;
    & strong {
      font-weight: 700;
      width: 50%;
    }
  }
`;

const OpeningHoursPage = () => {
  const openingQuery = useStaticQuery(graphql`
    {
      contactUs: craftContactUsContactUsEntry {
        richTextField
      }
      openingHours: craftOpeningHoursOpeningHoursEntry {
        title
        richTextField
        openingHours {
          day
          hours
        }
      }
    }
  `);
  // const contactUsRichText = openingQuery.contactUs.richTextField;
  const { title, openingHours, richTextField } = openingQuery.openingHours;

  return (
    <Layout
      backgroundColor={"var(--green)"}
      headerColor={"var(--white)"}
      title={title}
    >
      <StandalonePageLayout>
        <h1>{title}</h1>
        <div>
          <WhiteBox>
            <div>
              <HoursUL>
                {openingHours.map((hour, index) => (
                  <li key={index}>
                    <strong>{hour.day}</strong>
                    {hour.hours}
                  </li>
                ))}
              </HoursUL>
              <div dangerouslySetInnerHTML={{ __html: richTextField }} />
            </div>
          </WhiteBox>
          <div style={{ height: "400px" }} />
          {/* <ContactUsMap /> 
          <WhiteBox>
            <div dangerouslySetInnerHTML={{ __html: contactUsRichText }} />
          </WhiteBox>*/}
        </div>
      </StandalonePageLayout>
    </Layout>
  );
};

export default OpeningHoursPage;
